import React from "react";
import "./experiment-item.scss";

const Experiment = props => (
  <div className="xp">
    {/* <img className="product__img" src={props.productImg} alt="itsalive cover" /> */}
    <img className="xp__icon" src={props.xpIcon} alt={props.xpTitle} />
    <img
      className="xp__background"
      src={props.xpBackground}
      alt={props.xpTitle}
    />
    <div className="xp__content">
      <div className="xp__content__title">{props.xpTitle}</div>
      <div className="xp__content__desc">{props.xpDesc}</div>
    </div>
  </div>
);

export default Experiment;
